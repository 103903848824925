import React from "react";
import { convertDate } from "App/shared/utils/DateFormat";
import applicantMobileIcon from "App/shared/images/applicant-mobile-icon.png";
import shareDesktopIcon from "App/shared/images/share-desktop.png";

const defaultState = {
  updatePositionStoreFlag: false,
  positionList: [],
  loadingPositionList: false,
  positionListLoaded: false,
  loadingPositionDetails: false,
  positionDetailsLoaded: false,
  loadingPositionCategoryList: false,
  positionCategoryListLoaded: false,
  isPositionShareJobModalOpen: false,
  filterOptions: {},
  shareJobUrl: null,
  loadingShareJobUrl: false,
  shareJobUrlLoaded: false,
  positionListPageId: 1,
  positionListLimit: 10,
  totalCount: null,
  filters: [
    {
      name: "jobCategory",
      displayName: "Job Category",
      title: "Job Category",
    },
    {
      name: "status",
      displayName: "Status",
      title: "Status",
    },
    {
      name: "payType",
      displayName: "Pay Type",
      title: "Pay Type",
    },
    {
      name: "organisation",
      displayName: "Organization",
      title: "Organization",
    },
    {
      name: "role",
      displayName: "Role",
      title: "Role",
    },
  ],
  sortByFilter: {
    name: "sortBy",
    displayName: "Sort By",
    title: "Sort By",
  },
  dateRangeFilter: {
    name: "dateRange",
    displayName: "Date Range",
    title: "Date Range",
  },
  tableColumns: [
    {
      name: "Title",
      key: "title",
      className: "width-12",
      sortOnHeading: true,
    },
    {
      name: "External Job Id",
      key: "jobId",
      className: "width-12",
    },
    {
      name: "Organization",
      key: "organisation",
      className: "width-12",
      sortOnHeading: true,
    },
    {
      name: "Location",
      key: "location",
      className: "width-13",
    },
    {
      name: "Posted Date",
      key: "jobPostedDate",
      className: "width-15",
      // sortOnHeading: true,
    },
    {
      name: "Due Date",
      key: "jobPositionCloseDate",
      className: "width-15",
      sortOnHeading: true,
    },
    {
      name: "Status",
      key: "status",
      className: "width-10",
      sortOnHeading: true,
    },
    {
      name: <img src={applicantMobileIcon} alt="Applicant Count" />,
      key: "applicants",
      className: "width-4 remove-padding",
    },
    {
      name: '',
      key: "shareToPlatform",
      className: "width-5 remove-padding",
    },
  ],
  positionFormAccordionList: [
    {
      id: 1,
      title: "Basic Details",
      formFieldsName: [
        "jobTitle",
        "jobDescription",
        "jobSummary",
        "location",
        "contractDuration",
        "contractDurationMonths",
        "contractDurationWeeks",
        "jobCategory",
        "requisitionId",
        "jobStartDate",
        "jobCloseDate",
        "numberOfPositions",
        "seoKeywords",
        "jobCreatedBy",
        "platform",
        "jobPositionKeyExternal"
      ],
      totalErrors: 0,
      isAccordionOpen: true,
    },
    {
      id: 2,
      title: "Job Features",
      formFieldsName: [
        "autoComplete",
        "referalBonus",
        "payType",
        "payRate",
        "singleAmount",
        "amountRangeMin",
        "amountRangeMax",
        "workHour",
        "travelRequired",
        "remoteWorkAllowed",
      ],
      totalErrors: 0,
      isAccordionOpen: false,
    },
    {
      id: 3,
      title: "Education and Experience",
      formFieldsName: [
        "totalExperience",
        "singleExperience",
        "experienceRangeMin",
        "experienceRangeMax",
      ],
      totalErrors: 0,
      isAccordionOpen: false,
    },
  ],
  assignedToOptions: [
    { value: "Recruiter1", label: "Recruiter1", type: "assigned_to", id: 1 },
    { value: "Recruiter2", label: "Recruiter2", type: "assigned_to", id: 2 },
    { value: "Recruiter3", label: "Recruiter3", type: "assigned_to", id: 3 },
  ],
  sortByOptions: [
    {
      value: "title(ascending)",
      label: "title(ascending)",
      type: "sortBy",
      id: 1,
    },
    {
      value: "title(descending)",
      label: "title(descending)",
      type: "sortBy",
      id: 2,
    },
    {
      value: "organisation(ascending)",
      label: "organisation(ascending)",
      type: "sortBy",
      id: 3,
    },
    {
      value: "organisation(descending)",
      label: "organisation(descending)",
      type: "sortBy",
      id: 4,
    },
    {
      value: "Status(ascending)",
      label: "Status(ascending)",
      type: "sortBy",
      id: 5,
    },
    {
      value: "Status(descending)",
      label: "Status(descending)",
      type: "sortBy",
      id: 6,
    },
    {
      value: "Due Date(ascending)",
      label: "Due Date(ascending)",
      type: "sortBy",
      id: 7,
    },
    {
      value: "Due Date(descending)",
      label: "Due Date(descending)",
      type: "sortBy",
      id: 8,
    },
  ],
  statusOptions: [
    { value: "open", label: "Open", type: "status", id: 1 },
    { value: "closed", label: "Closed", type: "status", id: 2 },
    { value: "draft", label: "Draft", type: "status", id: 3 },
    { value: "active", label: "Active", type: "status", id: 5 },
  ],
  //position notify distribution list details
  distributionList: [],
  selectedNotifyDistributionListItems: [],
  notifiedDistributionListItems: [],

  filteredJobCategoryItems: [],
  filteredAssignedToItems: [],
  filteredJobTypeItems: [],
  filteredSortByItem: [],
  filteredDurationItem: [],
  filteredRoleItem: [
    {
      displayName: 'All',
      id: 1,
      label: 'All',
      type: 'role',
      value: 'All'
  },
  ],
  filteredStatusItems: [],
  filteredSearchKeys: [],
  filteredLocationSearchKeys: [],
  filteredSearchJobKeyKeys: [],
  filteredSortKey: {
    name: "",
    value: 0,
  },
  filterSelectedDateRange: [],
  filteredOrganisationItems: [],
  showMobileFilter: false,
  selectedMobileFilterItem: "duration",
  selectedDateRangeObject: {
    startDate: null,
    endDate: null,
  },
  positionFilters: {},
  yesNoOptions: [
    {
      id: 2,
      label: "No",
    },
    {
      id: 1,
      label: "Yes",
    },
  ],
  loadingPositionFilters: false,
  positionFiltersLoaded: false,
  isPositionDetailsLoaded: false,
  isPositionDetailsForEditPageLoaded: false,
  isPositionDetailsForDetailsPageLoaded: false,
  accordionStatus: {
    basicDetails: true,
    jobFeatures: false,
    educationExperience: false,
  },
  errorCount: {
    basicDetails: 0,
    jobFeatures: 0,
    educationExperience: 0,
  },
  numberOfEducationFieldsAllow: 30,
  numberOfExperienceFieldsAllow: 30,
  // create position state
  positionDetails: {
    isPublished: false,
    publishedDate: "",
    jobPositionUrl: "",
    selectedStatus: {},
    socialPlatformVendorDetails: [],
    //Basic details
    basicDetails: {
      jobTitle: "",
      jobDescription: "",
      jobSummary: "",
      locationDetails: {},
      defaultContractDurationSelectedOptionId: "monthly",
      contractDurationMonthsValue: "",
      contractDurationWeeksValue: "",
      isContractDurationMonthsInputDisabled: false,
      isContractDurationWeeksInputDisabled: true,
      requisitionId: "",
      numberOfPositions: "",
      selectedJobCategory: "",
      selectedJobSubCategory: "",
      jobPositionStartDate: null,
      jobPositionCloseingDate: null,
      seoKeywords: "",
      jobDepartmentName: "",
      selectedOrganisation: "",
      status: "",
      platform: '',
      jobPositionKeyExternal: ''
    },
    jobFeatures: {
      referalBonus: "",
      payTypeOptionId: 1,
      payTypeName: "",
      payRateOptionId: 1,
      payRateSingleAmountValue: "",
      isPayRateSingleAmountInputDisabled: false,
      payRateAmountRangeMinValue: "",
      payRateAmountRangeMaxValue: "",
      isPayRateRangeAmountInputDisabled: true,
      workHour: "",
      travelRequired: 2,
      travelRequiredInputValue: "",
      travelRequiredValueInputDisabled: true,
      remoteWorkAllowed: 2,
      remoteWorkAllowedDescription: "",
      remoteWorkAllowedDescriptionInputDisabled: true,
      ot: 2,
      hotJob: 2,
      showPayRateOnCareerPage: 2,
      skills: [],
      primarySkills: [],
      secondarySkills: [],
    },
    educationExperience: {
      educations: [
        {
          id: 1,
          description: "",
        },
      ],
      experience: [
        {
          id: 1,
          description: "",
        },
      ],
      totalExperienceOptionId: 1,
      totalExperienceSingleAmountValue: "",
      isTotalExperienceSingleAmountInputDisabled: false,
      totalExperienceAmountRangeMinValue: "",
      totalExperienceAmountRangeMaxValue: "",
      isTotalExperienceRangeAmountInputDisabled: true,
    },
    applicants: {
      applicantList: [],
      filterOptions: {},
      totalCount: 0,
    },
    suggestedApplicants: {
      applicantList: [],
      filterOptions: {},
      totalCount: 0,
    },
    distributionList: [],
  },
  showMorePositionDetails: false,
  contractDurationOptions: [
    {
      text: "Months",
      value: "monthly",
    },
    {
      text: "Weeks",
      value: "weekly",
    },
  ],
  jobStartingDateConfig: {
    selectedDate: "",
    minDate: new Date(),
  },
  jobClosingDateConfig: {
    selectedDate: "",
    minDate: new Date(),
  },
  payRateOptions: [
    {
      label: "Single Amount",
      id: 1,
    },
    {
      label: "Amount Range",
      id: 2,
    },
    {
      label: "Not Required",
      id: 3,
    },
  ],
  totalExperienceOptions: [
    {
      label: "Single Experience",
      id: 1,
    },
    {
      label: "Experience Range",
      id: 2,
    },
    {
      label: "Not Required",
      id: 3,
    },
  ],
  durationOptions: [
    {
      id: 1,
      value: "LAST 3 MONTHS",
      label: "Last 3 month",
      displayName: "LAST 3 MONTHS",
      days: "90days",
      type: "duration",
    },
    {
      id: 2,
      value: "LAST 6 MONTHS",
      label: "Last 6 month",
      displayName: "LAST 6 MONTHS",
      days: "181days",
      type: "duration",
    },
    {
      id: 3,
      value: "LAST 9 MONTHS",
      label: "Last 9 month",
      displayName: "LAST 9 MONTHS",
      days: "273days",
      type: "duration",
    },
    {
      id: 4,
      value: "LAST 12 MONTHS",
      label: "Last 12 month",
      displayName: "LAST 12 MONTHS",
      days: "365days",
      type: "duration",
    },
    {
      id: 5,
      value: "all",
      label: "All",
      displayName: "ALL",
      days: "all",
      type: "duration",
    },
  ],
  positionFormErrorDetails: null,
  positionDesktopFilterData: {
    jobDepartments: [],
    payTypes: [],
    status: [],
    sortBy: 1,
    pageId: 1,
    dateRange: {
      startDate: null,
      endDate: null,
    },
    organisations: [],
    sort: {
      name: "",
      value: null,
    },
  },
  currentPage: 0,
  isJobPositionFormCancelPopUpOpen: false,
  isPositionDetailPage: false,
  jobCategoryList: [],
  socialPlatformShareJobLink: false,
  socialPlatFormJobPositionId: "",
};

const RESET_POSITION_STATE = "RESET_POSITION_STATE";
const LOADING_POSITION_LIST = "LOADING_POSITION_LIST";
const OPEN_CLOSE_SHARE_JOB_MODAL = "OPEN_CLOSE_SHARE_JOB_MODAL";
const UPDATE_SHARE_JOB_URL_STATUS = "UPDATE_SHARE_JOB_URL_STATUS";
const POSITION_FORM_ACCORDION_LIST = "POSITION_FORM_ACCORDION_LIST";
const ON_CHANGE_POSITION_JOB_CATEGORY_FILTER =
  "ON_CHANGE_POSITION_JOB_CATEGORY_FILTER";
const ON_CHANGE_ORGANISATION_FILTER = "ON_CHANGE_ORGANISATION_FILTER";
const GET_FILTERED_ASSIGNED_TO_ITEMS = "GET_FILTERED_ASSIGNED_TO_ITEMS";
const ON_CHANGE_POSITION_JOB_TYPE_FILTER = "ON_CHANGE_POSITION_JOB_TYPE_FILTER";
const ON_CHANGE_POSITION_SORT_BY_FILTER = "ON_CHANGE_POSITION_SORT_BY_FILTER";
const ON_CHANGE_POSITION_STATUS_FILTER = "ON_CHANGE_POSITION_STATUS_FILTER";
const ON_CHANGE_POSITION_SEARCH_FILTER = "ON_CHANGE_POSITION_SEARCH_FILTER";
const ON_CHANGE_JOB_KEY_SEARCH_FILTER = "ON_CHANGE_JOB_KEY_SEARCH_FILTER";
const ON_CHANGE_POSITION_LOCATION_SEARCH_FILTER =
  "ON_CHANGE_POSITION_LOCATION_SEARCH_FILTER";
const ON_CHANGE_POSITION_DATE_RANGE_FILTER =
  "ON_CHANGE_POSITION_DATE_RANGE_FILTER";
const CLEAR_ALL_POSITION_FILTERS = "CLEAR_ALL_POSITION_FILTERS";
const SHOW_HIDE_MOBILE_POSITION_FILTER = "SHOW_HIDE_MOBILE_POSITION_FILTER";
const GET_MOBILE_FILTER_SELECTED_ITEM = "GET_MOBILE_FILTER_SELECTED_ITEM";
const ON_POSITION_PAGINATE = "ON_POSITION_PAGINATE";
const GET_MOBILE_PAGE_ID_ON_SCROLL = "GET_MOBILE_PAGE_ID_ON_SCROLL";
const POSITION_SHOW_MORE_DETAILS = "POSITION_SHOW_MORE_DETAILS";
const POSITION_UPDATE_STATUS = "POSITION_UPDATE_STATUS";
const APPLY_POSITION_MOBILE_FILTERS = "APPLY_POSITION_MOBILE_FILTERS";
const ON_TOGGLE_POSITION_ACCORDION = "ON_TOGGLE_POSITION_ACCORDION";
const LOADING_POSITION_FILTERS = "LOADING_POSITION_FILTERS";
const UPDATE_POSITION_FORM_ERROR_DETAILS = "UPDATE_POSITION_FORM_ERROR_DETAILS";
const UPDATE_POSITION_FORM_ERROR_COUNT = "UPDATE_POSITION_FORM_ERROR_COUNT";
const LOADING_POSITION_DETAILS = "LOADING_POSITION_DETAILS";
const ON_CHANGE_POSITION_TABLE_FILTER = "ON_CHANGE_POSITION_TABLE_FILTER";
const ON_CHANGE_POSITION_DURATION_FILTER = "ON_CHANGE_POSITION_DURATION_FILTER";
const ON_CHANGE_POSITION_ROLE_FILTER = "ON_CHANGE_POSITION_ROLE_FILTER";
const ON_LOADING_JOB_CATEGORY_LIST = "ON_LOADING_JOB_CATEGORY_LIST";

// Create Position
// Basic Details
const ON_CHANGE_JOB_TITLE_TEXT = "ON_CHANGE_JOB_TITLE_TEXT";
const ON_CHANGE_JOB_DESCRIPTION_TEXT = "ON_CHANGE_JOB_DESCRIPTION_TEXT";
const ON_CHANGE_JOB_SUMMARY_TEXT = "ON_CHANGE_JOB_SUMMARY_TEXT";
const ON_CHANGE_CONTRACT_DURATION_OPTION = "ON_CHANGE_CONTRACT_DURATION_OPTION";
const ON_CHANGE_CONTRACT_DURATION_OPTION_MONTHS =
  "ON_CHANGE_CONTRACT_DURATION_OPTION_MONTHS";
const ON_CHANGE_CONTRACT_DURATION_OPTION_WEEKS =
  "ON_CHANGE_CONTRACT_DURATION_OPTION_WEEKS";
const ON_CHANGE_REQUISITION_ID_TEXT = "ON_CHANGE_REQUISITION_ID_TEXT";
const ON_CHANGE_EXPECTED_START_DATE = "ON_CHANGE_EXPECTED_START_DATE";
const ON_CHANGE_EXPECTED_END_DATE = "ON_CHANGE_EXPECTED_END_DATE";
const ON_CHANGE_NUMBER_OF_POSITIONS_TEXT = "ON_CHANGE_NUMBER_OF_POSITIONS_TEXT";
const ON_CHANGE_JOB_CATEGORY_OPTION = "ON_CHANGE_JOB_CATEGORY_OPTION";
const ON_CHANGE_KEYWORDS_TEXT = "ON_CHANGE_KEYWORDS_TEXT";
const ON_CHANGE_POSITION_LOCATION = "ON_CHANGE_POSITION_LOCATION";
const ON_CHANGE_ORGANISATION_OPTION = "ON_CHANGE_ORGANISATION_OPTION";
const ON_CHANGE_JOB_SUB_CATEGORY_OPTION = "ON_CHANGE_JOB_SUB_CATEGORY_OPTION";

// Job Features
const ON_CHANGE_REFERAL_BONUS_TEXT = "ON_CHANGE_REFERAL_BONUS_TEXT";
const ON_CHANGE_PAY_TYPE_OPTION = "ON_CHANGE_PAY_TYPE_OPTION";
const ON_CHANGE_PAY_RATE_OPTION = "ON_CHANGE_PAY_RATE_OPTION";
const ON_CHANGE_PAY_RATE_SINGLE_AMOUNT_TEXT =
  "ON_CHANGE_PAY_RATE_SINGLE_AMOUNT_TEXT";
const ON_CHANGE_PAY_RATE_AMOUNT_MIN_RANGE_TEXT =
  "ON_CHANGE_PAY_RATE_AMOUNT_MIN_RANGE_TEXT";
const ON_CHANGE_PAY_RATE_AMOUNT_MAX_RANGE_TEXT =
  "ON_CHANGE_PAY_RATE_AMOUNT_MAX_RANGE_TEXT";
const ON_CHANGE_WORK_HOUR_TEXT = "ON_CHANGE_WORK_HOUR_TEXT";
const ON_CHANGE_TRAVEL_REQUIRED_OPTION = "ON_CHANGE_TRAVEL_REQUIRED_OPTION";
const ON_CHANGE_TRAVEL_REQUIRED_TEXT = "ON_CHANGE_TRAVEL_REQUIRED_TEXT";
const ON_CHANGE_REMOTE_WORK_ALLOWED_OPTION =
  "ON_CHANGE_REMOTE_WORK_ALLOWED_OPTION";
const ON_CHANGE_REMOTE_WORK_ALLOWED_TEXT = "ON_CHANGE_REMOTE_WORK_ALLOWED_TEXT";
const ON_CHANGE_OVERTIME_OPTION = "ON_CHANGE_OVERTIME_OPTION";
const ON_CHANGE_HOTJOB_OPTION = "ON_CHANGE_HOTJOB_OPTION";
const ON_CHANGE_SHOW_PAY_RATE_ON_CAREER_PAGE_OPTION =
  "ON_CHANGE_SHOW_PAY_RATE_ON_CAREER_PAGE_OPTION";
const ON_SELECT_JOB_SKILLS = "ON_SELECT_JOB_SKILLS";
const ON_SELECT_JOB_POSITION_PRIMARY_SKILLS =
  "ON_SELECT_JOB_POSITION_PRIMARY_SKILLS";
const ON_SELECT_JOB_POSITION_SECONDARY_SKILLS =
  "ON_SELECT_JOB_POSITION_SECONDARY_SKILLS";

//Education and Experience
const ON_EDUCATION_ADD = "ON_EDUCATION_ADD";
const ON_REMOVE_EDUCATION = "ON_REMOVE_EDUCATION";
const ON_CHANGE_EDUCATION_TEXT = "ON_CHANGE_EDUCATION_TEXT";
const ON_ADD_EXPERIENCE = "ON_ADD_EXPERIENCE";
const ON_REMOVE_EXPERIENCE = "ON_REMOVE_EXPERIENCE";
const ON_CHANGE_NOTIFY_POSITION_DISTRIBUTION_FILTER =
  "ON_CHANGE_NOTIFY_POSITION_DISTRIBUTION_FILTER";
const ON_CHANGE_EXPERIENCE_TEXT = "ON_CHANGE_EXPERIENCE_TEXT";
const ON_SOCIAL_SHARE_WINDOW_CLOSE = "ON_SOCIAL_SHARE_WINDOW_CLOSE";
const ON_POSITION_FORM_CANCEL_POP_UP_CHANGE =
  "ON_POSITION_FORM_CANCEL_POP_UP_CHANGE";
const ON_NAVIGATED_TO_POSITION_DETAIL_PAGE =
  "ON_NAVIGATED_TO_POSITION_DETAIL_PAGE";
const ON_CHANGE_TOTAL_EXPERIENCE_OPTION = "ON_CHANGE_TOTAL_EXPERIENCE_OPTION";
const ON_CHANGE_TOTAL_EXPERIENCE_SINGLE_AMOUNT_TEXT =
  "ON_CHANGE_TOTAL_EXPERIENCE_SINGLE_AMOUNT_TEXT";
const ON_CHANGE_TOTAL_EXPERIENCE_AMOUNT_MIN_RANGE_TEXT =
  "ON_CHANGE_TOTAL_EXPERIENCE_AMOUNT_MIN_RANGE_TEXT";
const ON_CHANGE_TOTAL_EXPERIENCE_AMOUNT_MAX_RANGE_TEXT =
  "ON_CHANGE_TOTAL_EXPERIENCE_AMOUNT_MAX_RANGE_TEXT";
const UPDATE_POSITION_STORE = "UPDATE_POSITION_STORE";
const UPDATE_ON_SOCIAL_PLATFORM = "UPDATE_ON_SOCIAL_PLATFORM";
const ON_CHANGE_PLATFORM = 'ON_CHANGE_PLATFORM'
const ON_CHANGE_JOB_POSITION_KEY= 'ON_CHANGE_JOB_POSITION_KEY';

//To share the JobLink from the social platform like facebook,twitter
const OPEN_CLOSE_SHARE_JOB_Link_MODAL = "OPEN_CLOSE_SHARE_JOB_Link_MODAL";
const SHARE_JOB_POSITION_ID = "SHARE_JOB_POSITION_ID"

function openCloseShareJobModal(status) {
  return {
    type: OPEN_CLOSE_SHARE_JOB_MODAL,
    status,
  };
}

function updateShareJobUrlStatus(status, url) {
  return {
    type: UPDATE_SHARE_JOB_URL_STATUS,
    status,
    url,
  };
}

function updateOnSocialPlatform(details) {
  return {
    type: UPDATE_ON_SOCIAL_PLATFORM,
    details,
  };
}

function updatePositionStore(status) {
  return {
    type: UPDATE_POSITION_STORE,
    status,
  };
}

function onPositionFormCancelPopUpChange(isJobPositionFormCancelPopUpOpen) {
  return {
    type: ON_POSITION_FORM_CANCEL_POP_UP_CHANGE,
    isJobPositionFormCancelPopUpOpen,
  };
}

function resetState() {
  return {
    type: RESET_POSITION_STATE,
  };
}

function updatePositionListLoadingStatus(status, positions = null) {
  return {
    type: LOADING_POSITION_LIST,
    status,
    positions,
  };
}

function updatePositionFiltersLoadingStatus(status, filters = null) {
  return {
    type: LOADING_POSITION_FILTERS,
    status,
    filters,
  };
}

function updatePositionDetailsLoadingStatus(status, positionDetails) {
  return {
    type: LOADING_POSITION_DETAILS,
    status,
    positionDetails,
  };
}

function onToggleAccordion(itemName) {
  return {
    type: ON_TOGGLE_POSITION_ACCORDION,
    itemName,
  };
}

function updatePositionFormErrorDetails(errorDetails = null) {
  return {
    type: UPDATE_POSITION_FORM_ERROR_DETAILS,
    errorDetails,
  };
}

function updatePositionFormErrorCount(formName, errorCount) {
  return {
    type: UPDATE_POSITION_FORM_ERROR_COUNT,
    formName,
    errorCount,
  };
}

function onPositionShowMoreDetailsAction() {
  return {
    type: POSITION_SHOW_MORE_DETAILS,
  };
}

function onPositionUpdateStatusAction(selectedStatus) {
  return {
    type: POSITION_UPDATE_STATUS,
    selectedStatus,
  };
}

// Basic Details Actions
function onChangeJobTitleText(jobTitle) {
  return {
    type: ON_CHANGE_JOB_TITLE_TEXT,
    jobTitle,
  };
}

function onChangeJobDescriptionText(jobDescription) {
  return {
    type: ON_CHANGE_JOB_DESCRIPTION_TEXT,
    jobDescription,
  };
}

function onChangeJobSummaryText(jobSummary) {
  return {
    type: ON_CHANGE_JOB_SUMMARY_TEXT,
    jobSummary,
  };
}

function onChangeContractDurationOption(contractDuration) {
  return {
    type: ON_CHANGE_CONTRACT_DURATION_OPTION,
    contractDuration,
  };
}

function onChangeContractDurationMonthsText(contractDurationMonthsValue) {
  return {
    type: ON_CHANGE_CONTRACT_DURATION_OPTION_MONTHS,
    contractDurationMonthsValue,
  };
}

function onChangeContractDurationWeeksText(contractDurationWeeksValue) {
  return {
    type: ON_CHANGE_CONTRACT_DURATION_OPTION_WEEKS,
    contractDurationWeeksValue,
  };
}

function onChangeRequisitionIdText(requisitionId) {
  return {
    type: ON_CHANGE_REQUISITION_ID_TEXT,
    requisitionId,
  };
}

function onChangeExpectedStartDate(jobPositionStartDate) {
  return {
    type: ON_CHANGE_EXPECTED_START_DATE,
    jobPositionStartDate,
  };
}

function onChangeExpectedEndDate(jobPositionCloseingDate) {
  return {
    type: ON_CHANGE_EXPECTED_END_DATE,
    jobPositionCloseingDate,
  };
}

function onChangeNumberOfPositionsText(numberOfPositions) {
  return {
    type: ON_CHANGE_NUMBER_OF_POSITIONS_TEXT,
    numberOfPositions,
  };
}

function onChangeJobCategoryOption(selectedJobCategory) {
  return {
    type: ON_CHANGE_JOB_CATEGORY_OPTION,
    selectedJobCategory,
  };
}

function onChangeJobSubCategoryOption(selectedJobSubCategory) {
  return {
    type: ON_CHANGE_JOB_SUB_CATEGORY_OPTION,
    selectedJobSubCategory,
  };
}

function onChangePlatform(platform) {
  return {
    type: ON_CHANGE_PLATFORM,
    platform,
  };
}
function onChangeJobPositionKeyExternal(jobPositionKeyExternal) {
  return {
    type: ON_CHANGE_JOB_POSITION_KEY,
    jobPositionKeyExternal,
  };
}

function onChangeOrganisationOption(selectedOrganisation) {
  return {
    type: ON_CHANGE_ORGANISATION_OPTION,
    selectedOrganisation,
  };
}

function onChangeKeywordsText(seoKeywords) {
  return {
    type: ON_CHANGE_KEYWORDS_TEXT,
    seoKeywords,
  };
}

function onChangePositionLocation(locationDetails) {
  return {
    type: ON_CHANGE_POSITION_LOCATION,
    locationDetails,
  };
}

// Job Features Actions
function onChangeReferalBonusText(referalBonus) {
  return {
    type: ON_CHANGE_REFERAL_BONUS_TEXT,
    referalBonus,
  };
}
function onChangePayTypeOption(payTypeOption) {
  return {
    type: ON_CHANGE_PAY_TYPE_OPTION,
    payTypeOption,
  };
}

function onChangePayRateOption(payRateOption) {
  return {
    type: ON_CHANGE_PAY_RATE_OPTION,
    payRateOption,
  };
}

function onChangePayRateSingleAmountText(payRateSingleAmountValue) {
  return {
    type: ON_CHANGE_PAY_RATE_SINGLE_AMOUNT_TEXT,
    payRateSingleAmountValue,
  };
}

function onChangePayRateAmountMinRangeText(payRateAmountRangeMinValue) {
  return {
    type: ON_CHANGE_PAY_RATE_AMOUNT_MIN_RANGE_TEXT,
    payRateAmountRangeMinValue,
  };
}

function onChangePayRateAmountMaxRangeText(payRateAmountRangeMaxValue) {
  return {
    type: ON_CHANGE_PAY_RATE_AMOUNT_MAX_RANGE_TEXT,
    payRateAmountRangeMaxValue,
  };
}

function onChangeWorkHourText(workHour) {
  return {
    type: ON_CHANGE_WORK_HOUR_TEXT,
    workHour,
  };
}

function onChangeDurationFilter(filteredDurationItem, loadData = true) {
  return {
    type: ON_CHANGE_POSITION_DURATION_FILTER,
    filteredDurationItem,
    loadData,
  };
}
function onChangeRoleFilter(filteredRoleItem, loadData = true) {
  return {
    type: ON_CHANGE_POSITION_ROLE_FILTER,
    filteredRoleItem,
    loadData,
  };
}

function onChangeTravelRequiredOption(travelRequiredOption) {
  return {
    type: ON_CHANGE_TRAVEL_REQUIRED_OPTION,
    travelRequiredOption,
  };
}

function onChangeTravelRequiredText(travelRequiredInputValue) {
  return {
    type: ON_CHANGE_TRAVEL_REQUIRED_TEXT,
    travelRequiredInputValue,
  };
}

function onChangeRemoteWorkAllowedOption(remoteWorkAllowed) {
  return {
    type: ON_CHANGE_REMOTE_WORK_ALLOWED_OPTION,
    remoteWorkAllowed,
  };
}

function onChangeRemoteWorkAllowedText(remoteWorkAllowedDescription) {
  return {
    type: ON_CHANGE_REMOTE_WORK_ALLOWED_TEXT,
    remoteWorkAllowedDescription,
  };
}

function onChangeOvertimeOption(overtimeOption) {
  return {
    type: ON_CHANGE_OVERTIME_OPTION,
    overtimeOption,
  };
}
function onChangeHotJobOption(hotJob) {
  return {
    type: ON_CHANGE_HOTJOB_OPTION,
    hotJob,
  };
}

function onChangeShowPayRateOnCareerPageOption(showPayRateOnCareerPageOption) {
  return {
    type: ON_CHANGE_SHOW_PAY_RATE_ON_CAREER_PAGE_OPTION,
    showPayRateOnCareerPageOption,
  };
}

function onSelectJobSkills(skills) {
  return {
    type: ON_SELECT_JOB_SKILLS,
    skills,
  };
}

const onSelectJobPositionPrimarySkills = (primarySkills) => ({
  type: ON_SELECT_JOB_POSITION_PRIMARY_SKILLS,
  primarySkills,
});

const onSelectJobPositionSecondarySkills = (secondarySkills) => ({
  type: ON_SELECT_JOB_POSITION_SECONDARY_SKILLS,
  secondarySkills,
});

// Education and Experience
function onChangeTotalExperienceOption(totalExperienceOption) {
  return {
    type: ON_CHANGE_TOTAL_EXPERIENCE_OPTION,
    totalExperienceOption,
  };
}
function onChangeTotalExperienceSingleAmountText(
  totalExperienceSingleAmountValue
) {
  return {
    type: ON_CHANGE_TOTAL_EXPERIENCE_SINGLE_AMOUNT_TEXT,
    totalExperienceSingleAmountValue,
  };
}

function onChangeTotalExperienceAmountMinRangeText(
  totalExperienceAmountRangeMinValue
) {
  return {
    type: ON_CHANGE_TOTAL_EXPERIENCE_AMOUNT_MIN_RANGE_TEXT,
    totalExperienceAmountRangeMinValue,
  };
}

function onChangeTotalExperienceAmountMaxRangeText(
  totalExperienceAmountRangeMaxValue
) {
  return {
    type: ON_CHANGE_TOTAL_EXPERIENCE_AMOUNT_MAX_RANGE_TEXT,
    totalExperienceAmountRangeMaxValue,
  };
}
function onAddEducation() {
  return {
    type: ON_EDUCATION_ADD,
  };
}

function onRemoveEducation(id) {
  return {
    type: ON_REMOVE_EDUCATION,
    id,
  };
}

function onChangeEducationText(education) {
  return {
    type: ON_CHANGE_EDUCATION_TEXT,
    education,
  };
}

function onAddExperience() {
  return {
    type: ON_ADD_EXPERIENCE,
  };
}

function onRemoveExperience(id) {
  return {
    type: ON_REMOVE_EXPERIENCE,
    id,
  };
}

function onChangeExperienceText(experience) {
  return {
    type: ON_CHANGE_EXPERIENCE_TEXT,
    experience,
  };
}

function onChangeAccordionListAction(positionFormAccordionList) {
  return {
    type: POSITION_FORM_ACCORDION_LIST,
    positionFormAccordionList,
  };
}

function onChangeJobCategoryFilter(filteredJobCategoryItems, loadData = true) {
  return {
    type: ON_CHANGE_POSITION_JOB_CATEGORY_FILTER,
    filteredJobCategoryItems,
    loadData,
  };
}

function onChangeOrganisationFilter(
  filteredOrganisationItems,
  loadData = true
) {
  return {
    type: ON_CHANGE_ORGANISATION_FILTER,
    filteredOrganisationItems,
    loadData,
  };
}

function onChangeNotifyPositionDistributionListFilter(
  filteredDistributionListItems
) {
  return {
    type: ON_CHANGE_NOTIFY_POSITION_DISTRIBUTION_FILTER,
    filteredDistributionListItems,
  };
}

function onChangeFilterAssignedTo(filteredAssignedToItems) {
  return {
    type: GET_FILTERED_ASSIGNED_TO_ITEMS,
    filteredAssignedToItems,
  };
}

function onChangeJobTypeFilter(filteredJobTypeItems, loadData = true) {
  return {
    type: ON_CHANGE_POSITION_JOB_TYPE_FILTER,
    filteredJobTypeItems,
    loadData,
  };
}

function onChangeSortByFilter(filteredSortByItem, loadData = true) {
  return {
    type: ON_CHANGE_POSITION_SORT_BY_FILTER,
    filteredSortByItem,
    loadData,
  };
}

function onChangePositionTableFilter(filteredSortKey, loadData = true) {
  return {
    type: ON_CHANGE_POSITION_TABLE_FILTER,
    filteredSortKey,
    loadData,
  };
}

function onChangeStatusFilter(filteredStatusItems, loadData = true) {
  return {
    type: ON_CHANGE_POSITION_STATUS_FILTER,
    filteredStatusItems,
    loadData,
  };
}

function onChangeSearchFilter(filteredSearchKeys, loadData = true) {
  return {
    type: ON_CHANGE_POSITION_SEARCH_FILTER,
    filteredSearchKeys,
    loadData,
  };
}

function onChangeLocationSearchFilter(
  filteredLocationSearchKeys,
  loadData = true
) {
  return {
    type: ON_CHANGE_POSITION_LOCATION_SEARCH_FILTER,
    filteredLocationSearchKeys,
    loadData,
  };
}

function onChangeJobKeySearchFilter(filteredSearchJobKeyKeys, loadData = true) {
  return {
    type: ON_CHANGE_JOB_KEY_SEARCH_FILTER,
    filteredSearchJobKeyKeys,
    loadData,
  };
}



function onChangeDateRangeFilter(filterSelectedDateRange, loadData = true) {
  return {
    type: ON_CHANGE_POSITION_DATE_RANGE_FILTER,
    filterSelectedDateRange,
    loadData,
  };
}

function onClearAllFilters() {
  return {
    type: CLEAR_ALL_POSITION_FILTERS,
  };
}

function onClickMobileFilter() {
  return {
    type: SHOW_HIDE_MOBILE_POSITION_FILTER,
  };
}

function onClickMobileSidebarItem(itemName) {
  return {
    type: GET_MOBILE_FILTER_SELECTED_ITEM,
    itemName,
  };
}

function onChangePagination(pageIndex, isScrollEvent = false) {
  return {
    type: ON_POSITION_PAGINATE,
    pageIndex,
    isScrollEvent,
  };
}

function onSocialShareWindowClose() {
  return {
    type: ON_SOCIAL_SHARE_WINDOW_CLOSE,
  };
}

function getMobilePageIdOnScroll() {
  return {
    type: GET_MOBILE_PAGE_ID_ON_SCROLL,
  };
}

function applyMobileFilters() {
  return {
    type: APPLY_POSITION_MOBILE_FILTERS,
  };
}

function onNavigatedToPositionDetailPage(isPositionDetailPage) {
  return {
    type: ON_NAVIGATED_TO_POSITION_DETAIL_PAGE,
    isPositionDetailPage,
  };
}

function _updatePositionBasicDetailsObject(positionDetails, detailsToUpdate) {
  return {
    ...positionDetails,
    basicDetails: { ...positionDetails.basicDetails, ...detailsToUpdate },
  };
}

function _updatePositionJobFeaturesObject(positionDetails, detailsToUpdate) {
  return {
    ...positionDetails,
    jobFeatures: { ...positionDetails.jobFeatures, ...detailsToUpdate },
  };
}

function _updatePositionEducationExperienceObject(
  positionDetails,
  detailsToUpdate
) {
  return {
    ...positionDetails,
    educationExperience: {
      ...positionDetails.educationExperience,
      ...detailsToUpdate,
    },
  };
}

function _resetAccordionStatus(accordionStatus) {
  const _accordionStatus = { ...accordionStatus };
  for (let key in _accordionStatus) {
    _accordionStatus[key] = false;
  }
  return _accordionStatus;
}

function _resetPositionFormErrorCount(errorCountObject) {
  const _errorCountObject = { ...errorCountObject };
  for (let key in errorCountObject) {
    _errorCountObject[key] = 0;
  }
  return _errorCountObject;
}

function _getFormatedDistributionList(distributionList) {
  const _distributionList = distributionList.filter(
    (option) => option.value !== "All"
  );
  return _distributionList.map(({ id: distributionId, value: title }) => ({
    distributionId,
    title,
  }));
}

function updatePositionCategoryListLoadingStatus(status, categoryList = []) {
  return {
    type: ON_LOADING_JOB_CATEGORY_LIST,
    status,
    categoryList,
  };
}

function openCloseShareJobLinkModal(status) {
  return {
    type: OPEN_CLOSE_SHARE_JOB_Link_MODAL,
    status
  }
}

function shareJobPositionId(positionId) {
  return {
    type: SHARE_JOB_POSITION_ID,
    positionId
  }
}

const positionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET_POSITION_STATE:
      return {
        ...defaultState,
      };
    case OPEN_CLOSE_SHARE_JOB_MODAL:
      return {
        ...state,
        isPositionShareJobModalOpen: action.status,
      };
    case UPDATE_SHARE_JOB_URL_STATUS:
      let shareJobUrlLoaded = false;
      if (action.status === false) {
        shareJobUrlLoaded = true;
      }
      return {
        ...state,
        shareJobUrlLoaded,
        loadingShareJobUrl: action.status,
        shareJobUrl: action.url ? action.url : null,
      };
    case LOADING_POSITION_LIST:
      let positionListLoaded = false;
      if (action.status === false) {
        positionListLoaded = true;
      }
      const positions = action.positions || {};
      const positionList = positions.positionList || [];
      const totalItemCount =
        state.positionListPageId === 1
          ? positions.totalCount
          : state.totalCount;
      let selectedDuration = [];
      if (state.filteredDurationItem.length === 0) {
        if (positionListLoaded === true) {
          const durationList =
            action.positions.filterOptions.DurationFilterType;
          selectedDuration = durationList
            ? [durationList[durationList.length - 1]]
            : [];
        } else {
          selectedDuration = state.filteredDurationItem;
        }
      } else {
        selectedDuration = state.filteredDurationItem;
      }

      return {
        ...state,
        loadingPositionList: action.status,
        positionListLoaded,
        filterOptions:
          positionListLoaded === true
            ? { ...positions.filterOptions }
            : { ...state.filterOptions },
        positionList: [...state.positionList, ...positionList],
        filteredDurationItem: selectedDuration,
        totalCount: totalItemCount,
      };
    case LOADING_POSITION_FILTERS:
      let positionFiltersLoaded = false;
      let distributionList =
        (action.filters && action.filters.distributionList) || [];

      if (action.status === false) {
        positionFiltersLoaded = true;
        // convert data structure to our format
        const formattedDistributionList = distributionList.map(
          ({ distributionId: id, title: value, description }) => ({
            id,
            value,
            description,
          })
        );
        //Adding All onLoad
        distributionList = [
          { id: new Date().getTime(), value: "All" },
          ...formattedDistributionList,
        ];
      }
      return {
        ...state,
        loadingPositionFilters: action.status,
        positionFiltersLoaded,
        positionFilters: action.filters ? { ...action.filters } : {},
        distributionList,
      };
    case ON_LOADING_JOB_CATEGORY_LIST:
      let positionCategoryListLoaded = false;
      let jobCategoryList = action.categoryList ? action.categoryList : [];
      if (action.status === false) {
        positionCategoryListLoaded = true;
      }

      return {
        ...state,
        loadingPositionCategoryList: action.status,
        positionCategoryListLoaded,
        jobCategoryList: jobCategoryList,
      };
    case LOADING_POSITION_DETAILS:
      let positionDetailsLoaded = false;
      if (action.status === false) {
        positionDetailsLoaded = true;
      }
      const details = { ...action.positionDetails } || {};
      const selectedDistributionList = details.distributionList;
      const _setSkillsObject = (skills) => {
        return skills.map(({ skillId: value, name: label }) => ({
          label,
          value,
        }));
      };
      const _setEducationExperienceObject = (data) => {
        return data.map(({ description }, index) => ({
          id: index + 1,
          description,
        }));
      };
      const _getLocationDetails = (locationDetails) => {
        if (locationDetails.address) {
          const {
            lat,
            lng,
            address: {
              addressLine1 = "",
              cityName = "",
              stateName = "",
              stateCode = "",
              zipCode = "",
              country = "",
            },
          } = locationDetails;
          return {
            lat,
            lng,
            address: {
              addressLine1,
              cityName,
              stateName,
              stateCode,
              country,
              zipCode,
            },
          };
        } else {
          return {};
        }
      };
      return {
        ...state,
        loadingPositionDetails: action.status,
        positionDetailsLoaded,
        //isPositionDetailsForEditPageLoaded: action.isPositionDetailsLoaded.isEditPageDetailsLoaded || false,
        //isPositionDetailsForDetailsPageLoaded: action.isPositionDetailsLoaded.isPositionDetailsForDetailsPageLoaded || false,
        positionDetails: {
          ...state.positionDetails,
          isPublished: details.isPublished || false,
          publishedDate: details.jobPositionPublishedDate || "",
          jobPositionUrl: details.jobPositionUrl || "",
          selectedStatus: details.status
            ? state.statusOptions.find(
                (status) => status.value === details.status
              )
            : {},
          socialPlatformVendorDetails: details.socialPlatformVendorDetails
            ? [...details.socialPlatformVendorDetails]
            : [],
          jobPositionId: details.jobPositionId,
          jobPositionTwitterUrl: details.jobPositionTwitterUrl,
          jobPositionLinkedInUrl: details.jobPositionLinkedInUrl,
          basicDetails: {
            ...state.positionDetails.basicDetails,
            jobTitle: details.title || "",
            jobId: details.jobPositionKey || "",
            jobDescription: details.description || "",
            jobSummary: details.summary || "",
            jobCreatedBy: details.jobCreatedBy || "",
            jobStatusUpdatedBy: details.jobStatusUpdatedBy || "",
            platform: details.platform || null,
            jobPositionKeyExternal: details.jobPositionKeyExternal || null,
            locationDetails: details.location
              ? _getLocationDetails(details.location)
              : {},
            requisitionId: details.requisitionId || "",
            selectedJobCategory: details.jobDepartment
              ? details.jobDepartment.jobDepartmentId
              : "",
            selectedJobSubCategory: details.jobSubDepartment
              ? details.jobSubDepartment.jobSubDepartmentId
              : "",
            selectedOrganisation: details.organisationDetails
              ? details.organisationDetails.organisationId
              : "",
            organisationDetails: details.organisationDetails,
            defaultContractDurationSelectedOptionId:
              details.contractDurationType || "monthly",
            contractDurationMonthsValue:
              details.contractDurationType === "monthly"
                ? details.contractDurationValue
                  ? details.contractDurationValue
                  : ""
                : "",
            contractDurationWeeksValue:
              details.contractDurationType === "weekly"
                ? details.contractDurationValue
                  ? details.contractDurationValue
                  : ""
                : "",
            isContractDurationMonthsInputDisabled: details.contractDurationType
              ? details.contractDurationType === "weekly"
                ? true
                : false
              : false,
            isContractDurationWeeksInputDisabled: details.contractDurationType
              ? details.contractDurationType === "monthly"
                ? true
                : false
              : true,
            numberOfPositions: details.numberOfPositions || "",
            jobPositionStartDate: details.jobPositionJoiningDate || null,
            jobPositionCloseingDate: details.jobPositionCloseDate || null,
            seoKeywords:
              (details.seoKeywords ? details.seoKeywords.join(",") : "") || "",
            jobDepartmentName: details.jobDepartment
              ? details.jobDepartment.name
              : "",
            jobSubDepartmentName:
              details.jobSubDepartment && details.jobSubDepartment.name
                ? details.jobSubDepartment.name
                : "-",
            status: details.status || "",
          },
          jobFeatures: {
            ...state.positionDetails.jobFeatures,
            referalBonus: details.referalBonus || "",
            payTypeOptionId: details.payDetails
              ? details.payDetails.payTypeId
                ? details.payDetails.payTypeId
                : 1
              : 1,
            payTypeName: details.payDetails ? details.payDetails.name : "",

            payRateOptionId: details.payDetails
              ? details.payDetails.payRate.type === "Range"
                ? 2
                : details.payDetails.payRate.type === "Single"
                ? 1
                : 3
              : 1,
            payRateSingleAmountValue: details.payDetails
              ? details.payDetails.payRate.value.totalRate
              : "",
            isPayRateSingleAmountInputDisabled: details.payDetails
              ? details.payDetails.payRate.type !== "Single"
                ? true
                : false
              : false,
            payRateAmountRangeMinValue: details.payDetails
              ? details.payDetails.payRate.value.minRate
                ? details.payDetails.payRate.value.minRate
                : ""
              : "",
            payRateAmountRangeMaxValue: details.payDetails
              ? details.payDetails.payRate.value.maxRate
                ? details.payDetails.payRate.value.maxRate
                : ""
              : "",
            isPayRateRangeAmountInputDisabled: details.payDetails
              ? details.payDetails.payRate.type !== "Range"
                ? true
                : false
              : true,

            workHour: details.workHours || "",
            travelRequired: details.isTravelRequired ? 1 : 2,
            travelRequiredInputValue: details.travelPercentage || "",
            travelRequiredValueInputDisabled: details.isTravelRequired
              ? false
              : true,
            remoteWorkAllowed: details.isRemoteWorkAllowed ? 1 : 2,
            remoteWorkAllowedDescription:
              details.remortWorkAllowedDescription || "",
            remoteWorkAllowedDescriptionInputDisabled:
              details.isRemoteWorkAllowed === 1 ? false : true,
            ot: details.ot ? 1 : 2,
            hotJob: details.isHotJob ? 1 : 2,
            showPayRateOnCareerPage: details.payDetails
              ? details.payDetails.payRate.value.showPayRateOnCareerPage
                ? 1
                : 2
              : 2,
            skills: details.skills ? _setSkillsObject(details.skills) : [],
            primarySkills: details.primarySkills
              ? _setSkillsObject(details.primarySkills)
              : [],
            secondarySkills: details.secondarySkills
              ? _setSkillsObject(details.secondarySkills)
              : [],
          },
          educationExperience: {
            ...state.positionDetails.educationExperience,
            totalExperienceOptionId: details.totalExperience
              ? details.totalExperience.type === "Range"
                ? 2
                : details.totalExperience.type === "Single"
                ? 1
                : 3
              : 1,
            totalExperienceSingleAmountValue: details.totalExperience
              ? details.totalExperience.value.totalExperience
              : "",
            isTotalExperienceSingleAmountInputDisabled: details.totalExperience
              ? details.totalExperience.type !== "Single"
                ? true
                : false
              : false,
            totalExperienceAmountRangeMinValue: details.totalExperience
              ? details.totalExperience.value.minExperience
                ? details.totalExperience.value.minExperience
                : ""
              : "",
            totalExperienceAmountRangeMaxValue: details.totalExperience
              ? details.totalExperience.value.maxExperience
                ? details.totalExperience.value.maxExperience
                : ""
              : "",
            isTotalExperienceRangeAmountInputDisabled: details.totalExperience
              ? details.totalExperience.type !== "Range"
                ? true
                : false
              : true,

            educations: details.education
              ? _setEducationExperienceObject(details.education)
              : [...state.positionDetails.educationExperience.educations],
            experience: details.experience
              ? _setEducationExperienceObject(details.experience)
              : [...state.positionDetails.educationExperience.experience],
          },
          applicants: {
            ...state.positionDetails.applicants,
            applicantList: details.applicants
              ? [...details.applicants.applicantList]
              : [],
            filterOptions: details.applicants
              ? { ...details.applicants.filterOptions }
              : {},
            totalCount: details.applicants
              ? details.applicants.totalCount || 0
              : 0,
          },
          suggestedApplicants: {
            ...state.positionDetails.suggestedApplicants,
            applicantList: details.suggestedApplicants
              ? [...details.suggestedApplicants.applicantList]
              : [],
            filterOptions: details.suggestedApplicants
              ? { ...details.suggestedApplicants.filterOptions }
              : {},
            totalCount: details.suggestedApplicants
              ? details.suggestedApplicants.totalCount || 0
              : 0,
          },
          distributionList: selectedDistributionList,
        },
        jobStartingDateConfig: {
          ...state.jobStartingDateConfig,
          selectedDate: details.jobPositionJoiningDate
            ? new Date(details.jobPositionJoiningDate)
            : "",
        },
        jobClosingDateConfig: {
          ...state.jobClosingDateConfig,
          selectedDate: details.jobPositionCloseDate
            ? new Date(details.jobPositionCloseDate)
            : "",
        },
        selectedNotifyDistributionListItems: selectedDistributionList,
      };
    case UPDATE_ON_SOCIAL_PLATFORM:
      return {
        ...state,
        positionDetails: {
          ...state.positionDetails,
          socialPlatformVendorDetails: action.details,
        },
      };
    case UPDATE_POSITION_FORM_ERROR_DETAILS:
      return {
        ...state,
        positionFormErrorDetails: action.errorDetails
          ? { ...action.errorDetails }
          : null,
        errorCount: _resetPositionFormErrorCount(state.errorCount),
        accordionStatus: action.errorDetails
          ? { ..._resetAccordionStatus(state.accordionStatus) }
          : { ...state.accordionStatus },
      };
    case UPDATE_POSITION_FORM_ERROR_COUNT:
      const errorCount = { ...state.errorCount };
      errorCount[action.formName] = action.errorCount;
      return {
        ...state,
        errorCount: { ...errorCount },
      };
    case POSITION_FORM_ACCORDION_LIST:
      return {
        ...state,
        positionFormAccordionList: [...action.positionFormAccordionList],
      };
    case UPDATE_POSITION_STORE: {
      return {
        ...state,
        updatePositionStoreFlag: action.status,
      };
    }
    case ON_CHANGE_NOTIFY_POSITION_DISTRIBUTION_FILTER:
      //here
      return {
        ...state,
        positionDetails: {
          ...state.positionDetails,
          distributionList: [
            ..._getFormatedDistributionList(
              action.filteredDistributionListItems
            ),
          ],
        },
        notifiedDistributionListItems: [
          ...action.filteredDistributionListItems,
        ],
      };
    case ON_CHANGE_POSITION_JOB_CATEGORY_FILTER:
      return {
        ...state,
        positionListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        positionListPageId: 1,
        filteredJobCategoryItems: [...action.filteredJobCategoryItems],
      };
    case ON_CHANGE_ORGANISATION_FILTER:
      return {
        ...state,
        positionListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        positionListPageId: 1,
        filteredOrganisationItems: [...action.filteredOrganisationItems],
      };
    case GET_FILTERED_ASSIGNED_TO_ITEMS:
      return {
        ...state,
        filteredAssignedToItems: [...action.filteredAssignedToItems],
      };
    case ON_CHANGE_POSITION_JOB_TYPE_FILTER:
      return {
        ...state,
        positionListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        positionListPageId: 1,
        filteredJobTypeItems: [...action.filteredJobTypeItems],
      };
    case ON_CHANGE_POSITION_SORT_BY_FILTER:
      return {
        ...state,
        positionListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        positionListPageId: 1,
        filteredSortByItem: action.filteredSortByItem
          ? [action.filteredSortByItem]
          : [],
        filteredSortKey: {
          name: "",
          value: 0,
        },
      };
    case ON_CHANGE_POSITION_TABLE_FILTER:
      return {
        ...state,
        positionListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        positionListPageId: 1,
        filteredSortKey: action.filteredSortKey
          ? {
              name: action.filteredSortKey.value,
              value: action.filteredSortKey.id,
            }
          : [],
      };
    case ON_CHANGE_POSITION_STATUS_FILTER:
      return {
        ...state,
        positionListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        positionListPageId: 1,
        filteredStatusItems: [...action.filteredStatusItems],
      };
    case ON_CHANGE_POSITION_DURATION_FILTER:
      return {
        ...state,
        positionListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        positionListPageId: 1,
        filteredDurationItem: [action.filteredDurationItem],
      };
    case ON_CHANGE_POSITION_ROLE_FILTER:
      return {
        ...state,
        positionListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        positionListPageId: 1,
        filteredRoleItem: [action.filteredRoleItem],
      };
    case ON_CHANGE_POSITION_SEARCH_FILTER:
      return {
        ...state,
        positionListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        positionListPageId: 1,
        filteredSearchKeys: [...action.filteredSearchKeys],
      };
    case ON_CHANGE_POSITION_LOCATION_SEARCH_FILTER:
      return {
        ...state,
        positionListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        positionListPageId: 1,
        filteredLocationSearchKeys: [...action.filteredLocationSearchKeys],
      };
      case ON_CHANGE_JOB_KEY_SEARCH_FILTER:
      return {
        ...state,
        positionListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        positionListPageId: 1,
        filteredSearchJobKeyKeys: [...action.filteredSearchJobKeyKeys],
      };
    case ON_CHANGE_POSITION_DATE_RANGE_FILTER:
      let filterSelectedDateRange = [];
      if (
        action.filterSelectedDateRange.startDate &&
        action.filterSelectedDateRange.endDate
      ) {
        filterSelectedDateRange = [
          {
            id: 1,
            startDate: action.filterSelectedDateRange.startDate,
            endDate: action.filterSelectedDateRange.endDate,
            value: `${convertDate(
              action.filterSelectedDateRange.startDate,
              ["mm", "DD", "YYYY"],
              "/"
            )} - ${convertDate(
              action.filterSelectedDateRange.endDate,
              ["mm", "DD", "YYYY"],
              "/"
            )}`,
            type: "dateRange",
          },
        ];
      }
      return {
        ...state,
        positionListLoaded: !action.loadData,
        positionList: action.loadData === true ? [] : [...state.positionList],
        positionListPageId: 1,
        filterSelectedDateRange,
        selectedDateRangeObject: {
          startDate: action.filterSelectedDateRange.startDate,
          endDate: action.filterSelectedDateRange.endDate,
        },
      };
    case APPLY_POSITION_MOBILE_FILTERS:
      return {
        ...state,
        positionListLoaded: false,
        showMobileFilter: false,
        positionList: [],
        positionListPageId: 1,
      };
    case CLEAR_ALL_POSITION_FILTERS:
      return {
        ...state,
        positionListLoaded: false,
        positionList: [],
        positionListPageId: 1,
        filteredSortByItem: [],
        filteredJobCategoryItems: [],
        filteredStatusItems: [],
        filteredJobTypeItems: [],
        filteredDurationItem: [
          {
            id: 1,
            value: "LAST 3 MONTHS",
            label: "Last 3 month",
            displayName: "LAST 3 MONTHS",
            days: "90days",
            type: "duration",
          },
        ],
        filteredRoleItem: [
          {
            displayName: 'All',
            id: 1,
            label: 'All',
            type: 'role',
            value: 'All'
        },
        ],
        filterSelectedDateRange: [],
        filteredOrganisationItems: [],
        filteredSearchKeys: [],
        selectedDateRangeObject: {
          startDate: null,
          endDate: null,
        },
      };
    case SHOW_HIDE_MOBILE_POSITION_FILTER:
      return {
        ...state,
        showMobileFilter: !state.showMobileFilter,
      };
    case ON_TOGGLE_POSITION_ACCORDION:
      const accordionStatus = { ...state.accordionStatus };
      accordionStatus[action.itemName] = !accordionStatus[action.itemName];
      return {
        ...state,
        accordionStatus: { ...accordionStatus },
      };
    case GET_MOBILE_FILTER_SELECTED_ITEM:
      return {
        ...state,
        selectedMobileFilterItem: action.itemName,
      };
    case ON_CHANGE_JOB_TITLE_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            jobTitle: action.jobTitle.trim() !== "" ? action.jobTitle : "",
          }
        ),
      };
    case ON_CHANGE_JOB_DESCRIPTION_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            jobDescription:
              action.jobDescription.trim() !== "" ? action.jobDescription : "",
          }
        ),
      };
    case ON_CHANGE_JOB_SUMMARY_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            jobSummary:
              action.jobSummary.trim() !== "" ? action.jobSummary : "",
          }
        ),
      };
    case ON_CHANGE_CONTRACT_DURATION_OPTION:
      const isContractDurationMonthsInputDisabled =
        action.contractDuration.id === "monthly" ? false : true;
      const isContractDurationWeeksInputDisabled =
        action.contractDuration.id === "weekly" ? false : true;
      const contractDurationMonthsValue = "";
      const contractDurationWeeksValue = "";

      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            defaultContractDurationSelectedOptionId: action.contractDuration.id,
            isContractDurationMonthsInputDisabled,
            isContractDurationWeeksInputDisabled,
            contractDurationMonthsValue,
            contractDurationWeeksValue,
          }
        ),
      };
    case ON_CHANGE_CONTRACT_DURATION_OPTION_MONTHS:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            contractDurationMonthsValue:
              action.contractDurationMonthsValue <= 0
                ? ""
                : action.contractDurationMonthsValue,
          }
        ),
      };
    case ON_CHANGE_CONTRACT_DURATION_OPTION_WEEKS:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            contractDurationWeeksValue:
              action.contractDurationWeeksValue <= 0
                ? ""
                : action.contractDurationWeeksValue,
          }
        ),
      };
    case ON_CHANGE_REQUISITION_ID_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            requisitionId: action.requisitionId,
          }
        ),
      };
    case ON_CHANGE_EXPECTED_START_DATE:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            jobPositionStartDate: action.jobPositionStartDate,
          }
        ),
        jobStartingDateConfig: {
          ...state.jobStartingDateConfig,
          selectedDate: action.jobPositionStartDate
            ? new Date(action.jobPositionStartDate)
            : "",
        },
      };
    case ON_CHANGE_EXPECTED_END_DATE:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            jobPositionCloseingDate: action.jobPositionCloseingDate,
          }
        ),
        jobClosingDateConfig: {
          ...state.jobClosingDateConfig,
          selectedDate: action.jobPositionCloseingDate
            ? new Date(action.jobPositionCloseingDate)
            : "",
        },
      };
    case ON_CHANGE_NUMBER_OF_POSITIONS_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            numberOfPositions:
              action.numberOfPositions <= 0 ? "" : action.numberOfPositions,
          }
        ),
      };
    case ON_CHANGE_JOB_CATEGORY_OPTION:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            selectedJobCategory: action.selectedJobCategory,
            selectedJobSubCategory: "",
          }
        ),
      };
    case ON_CHANGE_PLATFORM:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            platform: action.platform,
          }
        ),
      };
    case ON_CHANGE_JOB_POSITION_KEY:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            jobPositionKeyExternal: action.jobPositionKeyExternal,
            platform:''
          }
        ),
      };
    case ON_CHANGE_JOB_SUB_CATEGORY_OPTION:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            selectedJobSubCategory: action.selectedJobSubCategory,
          }
        ),
      };
    case ON_CHANGE_ORGANISATION_OPTION:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            selectedOrganisation: action.selectedOrganisation,
          }
        ),
      };
    case ON_CHANGE_KEYWORDS_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionBasicDetailsObject(
          state.positionDetails,
          {
            seoKeywords:
              action.seoKeywords.trim() !== "" ? action.seoKeywords : "",
          }
        ),
      };
    case ON_CHANGE_REFERAL_BONUS_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            referalBonus: action.referalBonus,
          }
        ),
      };
    case ON_CHANGE_PAY_TYPE_OPTION:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            payTypeOptionId: action.payTypeOption.id,
          }
        ),
      };
    case ON_CHANGE_PAY_RATE_OPTION:
      const isPayRateSingleAmountInputDisabled =
        action.payRateOption.id === 1 ? false : true;
      const isPayRateRangeAmountInputDisabled =
        action.payRateOption.id === 2 ? false : true;
      const payRateSingleAmountValue = "";
      const payRateAmountRangeMinValue = "";
      const payRateAmountRangeMaxValue = "";

      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            payRateOptionId: action.payRateOption.id,
            isPayRateSingleAmountInputDisabled,
            isPayRateRangeAmountInputDisabled,
            payRateSingleAmountValue,
            payRateAmountRangeMinValue,
            payRateAmountRangeMaxValue,
            showPayRateOnCareerPage: 2,
          }
        ),
      };
    case ON_CHANGE_PAY_RATE_SINGLE_AMOUNT_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            payRateSingleAmountValue: action.payRateSingleAmountValue,
          }
        ),
      };
    case ON_CHANGE_PAY_RATE_AMOUNT_MIN_RANGE_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            payRateAmountRangeMinValue: action.payRateAmountRangeMinValue,
          }
        ),
      };
    case ON_CHANGE_PAY_RATE_AMOUNT_MAX_RANGE_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            payRateAmountRangeMaxValue: action.payRateAmountRangeMaxValue,
          }
        ),
      };
    case ON_CHANGE_WORK_HOUR_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            workHour: action.workHour,
          }
        ),
      };
    case ON_CHANGE_TRAVEL_REQUIRED_OPTION:
      const travelRequiredInputValue =
        state.positionDetails.jobFeatures.travelRequired === 1
          ? ""
          : state.positionDetails.jobFeatures.travelRequiredInputValue;
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            travelRequired: action.travelRequiredOption.id,
            travelRequiredValueInputDisabled:
              action.travelRequiredOption.id === 1 ? false : true,
            travelRequiredInputValue,
          }
        ),
      };
    case ON_CHANGE_TRAVEL_REQUIRED_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            travelRequiredInputValue: action.travelRequiredInputValue,
          }
        ),
      };
    case ON_CHANGE_REMOTE_WORK_ALLOWED_OPTION:
      const remoteWorkAllowedDescription =
        state.positionDetails.jobFeatures.remoteWorkAllowed === 1
          ? ""
          : state.positionDetails.jobFeatures.remoteWorkAllowedDescription;
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            remoteWorkAllowed: action.remoteWorkAllowed.id,
            remoteWorkAllowedDescriptionInputDisabled:
              action.remoteWorkAllowed.id === 1 ? false : true,
            remoteWorkAllowedDescription,
          }
        ),
      };
    case ON_CHANGE_REMOTE_WORK_ALLOWED_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            remoteWorkAllowedDescription: action.remoteWorkAllowedDescription,
          }
        ),
      };
    case ON_CHANGE_OVERTIME_OPTION:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            ot: action.overtimeOption.id,
          }
        ),
      };
    case ON_CHANGE_HOTJOB_OPTION:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            hotJob: action.hotJob.id,
          }
        ),
      };
    case ON_CHANGE_SHOW_PAY_RATE_ON_CAREER_PAGE_OPTION:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            showPayRateOnCareerPage: action.showPayRateOnCareerPageOption.id,
          }
        ),
      };

    case ON_CHANGE_TOTAL_EXPERIENCE_OPTION:
      const isTotalExperienceSingleAmountInputDisabled =
        action.totalExperienceOption.id === 1 ? false : true;
      const isTotalExperienceRangeAmountInputDisabled =
        action.totalExperienceOption.id === 2 ? false : true;
      const totalExperienceSingleAmountValue = "";
      const totalExperienceAmountRangeMinValue = "";
      const totalExperienceAmountRangeMaxValue = "";

      return {
        ...state,
        positionDetails: _updatePositionEducationExperienceObject(
          state.positionDetails,
          {
            totalExperienceOptionId: action.totalExperienceOption.id,
            isTotalExperienceSingleAmountInputDisabled,
            isTotalExperienceRangeAmountInputDisabled,
            totalExperienceSingleAmountValue,
            totalExperienceAmountRangeMinValue,
            totalExperienceAmountRangeMaxValue,
            // showPayRateOnCareerPage: 2
          }
        ),
      };
    case ON_CHANGE_TOTAL_EXPERIENCE_SINGLE_AMOUNT_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionEducationExperienceObject(
          state.positionDetails,
          {
            totalExperienceSingleAmountValue:
              action.totalExperienceSingleAmountValue,
          }
        ),
      };
    case ON_CHANGE_TOTAL_EXPERIENCE_AMOUNT_MIN_RANGE_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionEducationExperienceObject(
          state.positionDetails,
          {
            totalExperienceAmountRangeMinValue:
              action.totalExperienceAmountRangeMinValue,
          }
        ),
      };
    case ON_CHANGE_TOTAL_EXPERIENCE_AMOUNT_MAX_RANGE_TEXT:
      return {
        ...state,
        positionDetails: _updatePositionEducationExperienceObject(
          state.positionDetails,
          {
            totalExperienceAmountRangeMaxValue:
              action.totalExperienceAmountRangeMaxValue,
          }
        ),
      };

    case ON_EDUCATION_ADD:
      const educations =
        state.positionDetails.educationExperience.educations.length !==
        state.numberOfEducationFieldsAllow
          ? [
              ...state.positionDetails.educationExperience.educations,
              {
                id:
                  state.positionDetails.educationExperience.educations.length +
                  1,
                description: "",
              },
            ]
          : [...state.positionDetails.educationExperience.educations];
      return {
        ...state,
        positionDetails: _updatePositionEducationExperienceObject(
          state.positionDetails,
          {
            educations,
          }
        ),
      };
    case ON_REMOVE_EDUCATION:
      let educationsList = [
        ...state.positionDetails.educationExperience.educations,
      ];
      if (educationsList.length !== 1) {
        educationsList = educationsList.filter((item) => item.id !== action.id);
        educationsList = educationsList.map((item, index) => ({
          id: index + 1,
          description: item.description,
        }));
      }
      return {
        ...state,
        positionDetails: _updatePositionEducationExperienceObject(
          state.positionDetails,
          {
            educations: educationsList,
          }
        ),
      };
    case ON_CHANGE_EDUCATION_TEXT:
      const selectedEducation = state.positionDetails.educationExperience.educations.find(
        (item) => item.id === action.education.id
      );
      selectedEducation.description =
        action.education.description.trim() !== ""
          ? action.education.description
          : "";
      const updatedEducationsList = [
        ...state.positionDetails.educationExperience.educations,
      ];
      return {
        ...state,
        positionDetails: _updatePositionEducationExperienceObject(
          state.positionDetails,
          {
            educations: updatedEducationsList,
          }
        ),
      };
    case ON_ADD_EXPERIENCE:
      const experience =
        state.positionDetails.educationExperience.experience.length !==
        state.numberOfExperienceFieldsAllow
          ? [
              ...state.positionDetails.educationExperience.experience,
              {
                id:
                  state.positionDetails.educationExperience.experience.length +
                  1,
                description: "",
              },
            ]
          : [...state.positionDetails.educationExperience.experience];
      return {
        ...state,
        positionDetails: _updatePositionEducationExperienceObject(
          state.positionDetails,
          {
            experience,
          }
        ),
      };
    case ON_REMOVE_EXPERIENCE:
      let experienceList = [
        ...state.positionDetails.educationExperience.experience,
      ];
      if (experienceList.length !== 1) {
        experienceList = experienceList.filter((item) => item.id !== action.id);
        experienceList = experienceList.map((item, index) => ({
          id: index + 1,
          description: item.description,
        }));
      }
      return {
        ...state,
        positionDetails: _updatePositionEducationExperienceObject(
          state.positionDetails,
          {
            experience: experienceList,
          }
        ),
      };
    case ON_CHANGE_EXPERIENCE_TEXT:
      const selectedExperience = state.positionDetails.educationExperience.experience.find(
        (item) => item.id === action.experience.id
      );
      selectedExperience.description =
        action.experience.description.trim() !== ""
          ? action.experience.description
          : "";
      const updatedExperienceList = [
        ...state.positionDetails.educationExperience.experience,
      ];
      return {
        ...state,
        positionDetails: _updatePositionEducationExperienceObject(
          state.positionDetails,
          {
            experience: updatedExperienceList,
          }
        ),
      };
    case ON_SELECT_JOB_SKILLS:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            skills: [...action.skills],
          }
        ),
      };
    case ON_SELECT_JOB_POSITION_PRIMARY_SKILLS:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            primarySkills: [...action.primarySkills],
          }
        ),
      };
    case ON_SELECT_JOB_POSITION_SECONDARY_SKILLS:
      return {
        ...state,
        positionDetails: _updatePositionJobFeaturesObject(
          state.positionDetails,
          {
            secondarySkills: [...action.secondarySkills],
          }
        ),
      };
    case ON_POSITION_PAGINATE:
      return {
        ...state,
        positionListLoaded: false,
        positionListPageId: action.pageIndex,
        positionList:
          action.isScrollEvent === true ? [...state.positionList] : [],
      };
    case ON_SOCIAL_SHARE_WINDOW_CLOSE:
      return {
        ...state,
        positionDetailsLoaded: false,
      };
    case GET_MOBILE_PAGE_ID_ON_SCROLL:
      return {
        ...state,
        mobilePageId: state.mobilePageId + 1,
      };
    case ON_CHANGE_POSITION_LOCATION:
      return {
        ...state,
        positionDetails: {
          ...state.positionDetails,
          basicDetails: {
            ...state.positionDetails.basicDetails,
            locationDetails: {
              ...action.locationDetails,
            },
          },
        },
      };
    case POSITION_SHOW_MORE_DETAILS:
      return {
        ...state,
        showMorePositionDetails: !state.showMorePositionDetails,
      };
    case POSITION_UPDATE_STATUS:
      return {
        ...state,
        positionDetails: {
          ...state.positionDetails,
          selectedStatus: action.selectedStatus,
        },
      };
    case ON_POSITION_FORM_CANCEL_POP_UP_CHANGE:
      return {
        ...state,
        isJobPositionFormCancelPopUpOpen:
          action.isJobPositionFormCancelPopUpOpen,
      };
    case ON_NAVIGATED_TO_POSITION_DETAIL_PAGE:
      return {
        ...state,
        isPositionDetailPage: action.isPositionDetailPage,
      };
    case OPEN_CLOSE_SHARE_JOB_Link_MODAL:
      return {
        ...state,
        socialPlatformShareJobLink: action.status
      }
    case SHARE_JOB_POSITION_ID:
      return {
        ...state,
        socialPlatFormJobPositionId: action.positionId
      }
    default:
      return state;
  }
};

export {
  resetState,
  updateShareJobUrlStatus,
  openCloseShareJobModal,
  positionReducer as PositionReducer,
  updatePositionListLoadingStatus,
  updatePositionDetailsLoadingStatus,
  onChangeAccordionListAction,
  onChangeNotifyPositionDistributionListFilter,
  onChangeJobCategoryFilter,
  onChangeOrganisationFilter,
  onChangeFilterAssignedTo,
  onChangeJobTypeFilter,
  onChangeSortByFilter,
  onChangeStatusFilter,
  onChangeSearchFilter,
  onChangeLocationSearchFilter,
  onChangeDateRangeFilter,
  onClearAllFilters,
  onClickMobileFilter,
  onClickMobileSidebarItem,
  onPositionShowMoreDetailsAction,
  onPositionUpdateStatusAction,
  //BASIC DETAILS FORM ACTIONS
  onChangeJobTitleText,
  onChangeJobDescriptionText,
  onChangeJobSummaryText,
  onChangeContractDurationOption,
  onChangeContractDurationMonthsText,
  onChangeContractDurationWeeksText,
  onChangeRequisitionIdText,
  onChangeExpectedStartDate,
  onChangeExpectedEndDate,
  onChangeNumberOfPositionsText,
  onChangeJobCategoryOption,
  onChangeKeywordsText,
  onChangePositionLocation,
  onChangeOrganisationOption,
  //JOB FEATURES FORM ACTIONS
  onChangeReferalBonusText,
  onChangePayTypeOption,
  onChangePayRateOption,
  onChangePayRateSingleAmountText,
  onChangePayRateAmountMinRangeText,
  onChangePayRateAmountMaxRangeText,
  onChangeTotalExperienceOption,
  onChangeTotalExperienceSingleAmountText,
  onChangeTotalExperienceAmountMinRangeText,
  onChangeTotalExperienceAmountMaxRangeText,
  onChangeWorkHourText,
  onChangeTravelRequiredOption,
  onChangeTravelRequiredText,
  onChangeRemoteWorkAllowedOption,
  onChangeRemoteWorkAllowedText,
  onChangeOvertimeOption,
  onChangeHotJobOption,
  onSelectJobSkills,
  onSelectJobPositionPrimarySkills,
  onSelectJobPositionSecondarySkills,
  onChangeShowPayRateOnCareerPageOption,
  // EDUCATION AND EXPERIENCE FORM ACTION
  onAddEducation,
  onRemoveEducation,
  onChangeEducationText,
  onAddExperience,
  onRemoveExperience,
  onChangeExperienceText,
  onChangePagination,
  getMobilePageIdOnScroll,
  applyMobileFilters,
  onToggleAccordion,
  updatePositionFiltersLoadingStatus,
  updatePositionFormErrorDetails,
  updatePositionFormErrorCount,
  onPositionFormCancelPopUpChange,
  onSocialShareWindowClose,
  onNavigatedToPositionDetailPage,
  onChangePositionTableFilter,
  updatePositionStore,
  updateOnSocialPlatform,
  onChangeDurationFilter,
  onChangeRoleFilter,
  onChangeJobSubCategoryOption,
  updatePositionCategoryListLoadingStatus,
  onChangeJobKeySearchFilter,
  onChangePlatform,
  onChangeJobPositionKeyExternal,
  openCloseShareJobLinkModal,
  shareJobPositionId,
};
